import { ref } from 'vue';
export default {
  name: 'ContactChange',
  components: {},

  setup() {
    const show = ref(false);

    const showTip = () => {
      show.value = true;
    };

    const hideTip = () => {
      show.value = false;
    };

    return {
      show,
      showTip,
      hideTip
    };
  }

};