import store from '@/store';
import { nextTick } from 'vue'; // @ts-ignore
// import VConsole from 'vconsole/dist/vconsole.min'
// const vConsole = new VConsole()

export default {
  name: 'CollectBase',

  setup() {
    nextTick(() => {
      if (window.HybridAPI !== undefined) {
        window.HybridAPI.onReceiveUser = msg => {
          const data = JSON.parse(msg);
          store.dispatch('SaveToken', data.token);
        };

        const param = {
          method: 'getUserInfo',
          params: {},
          callback: 'onReceiveUser'
        };
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }
    });
    return {// vConsole
    };
  }

};